import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Video = makeShortcode("Video");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "its-fundamental-to-everything-we-design-the-2x-grid-is-the-geometric-foundation-of-all-the-visual-elements-of-ibm-design-from-typography-to-columns-boxes-icons-and-illustrations-it-provides-structure-and-guidance-for-all-creative-decision-making"
    }}>{`It’s fundamental to everything we design. The 2x grid is the geometric foundation of all the visual elements of IBM Design, from typography to columns, boxes, icons, and illustrations. It provides structure and guidance for all creative decision-making.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Mini unit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">2x grid fundamentals</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Grid behaviors</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Grid influencers</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Sizing scale</AnchorLink>
    </AnchorLinks>
    <Video vimeoId="281513522" mdxType="Video" />
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="IBM Grid Template" href="https://sketch.cloud/s/ngV7z" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Elements package: Grid" href="https://github.com/carbon-design-system/carbon/tree/master/packages/grid" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Grid package preview" href="https://carbon-elements.netlify.com/grid/examples/preview/" mdxType="ResourceCard">
          <MdxIcon name="codesandbox" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "mini-unit"
    }}>{`Mini unit`}</h2>
    <p>{`The basic unit of 2x grid geometry is the 8-pixel square `}<em parentName="p">{`Mini unit`}</em>{`. Multiples of Mini Units compose the dimensions of columns, rows, boxes, along with their margins and padding. The Mini Unit adapts to your content while maintaining a consistent visual rhythm.`}</p>
    <p>{`At breakpoint boundaries, mini units divide the screen into a fixed master grid, and multiples of mini units map to fluid grid column widths and row heights. Fixed boxes are sized in mini units, as are the fixed dimension of hybrid boxes.`}</p>
    <p>{`Margin and padding are always applied in fixed mini unit multiples. Boxes are sized by applying a sizing scale based on either fixed mini units or fluid column widths, along with a core set of aspect ratios.`}</p>
    <Video vimeoId="292608020" mdxType="Video" />
    <h2 {...{
      "id": "2x-grid-fundamentals"
    }}>{`2x grid fundamentals`}</h2>
    <p>{`The core concept of the 2x grid is to divide or multiply by two, forming a visual rhythm. Fluid grids are built by division, whereas fixed grids are built with multiplication. On breakpoint boundaries, these sizes match. Margins and padding are applied consistently in both cases.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Columns and rows</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Margins</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Padding</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Gutters</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Breakpoints</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "columns-and-rows"
    }}>{`Columns and Rows`}</h3>
    <p>{`Columns and rows create key lines that are essential for visual rhythm, especially for typography. Construct columns by either dividing a space into a fluid grid, or by tiling fixed boxes in multiples.`}</p>
    <img {...{
      "src": "/0302ac75ce345781aa42298affd6184c/Layout_overview_Columns-rows.svg",
      "alt": "Columns and rows"
    }}></img>
    <h4 {...{
      "id": "fluid-grid"
    }}>{`Fluid grid`}</h4>
    <p>{`A `}<a parentName="p" {...{
        "href": "#fluid-grid"
      }}>{`fluid grid`}</a>{` divides space by twos. This division is immediately evident in the 2x column structure. Within a breakpoint, the column count is constant, and unit size scales with screen size. Choose a column count by starting with one, then divide by two as needed:`}</p>
    <h4 {...{
      "id": "fixed-grid"
    }}>{`Fixed grid`}</h4>
    <p>{`A `}<a parentName="p" {...{
        "href": "#fixed-grid"
      }}>{`fixed grid`}</a>{` starts with a fixed unit size from the sizing scale, then tiles and wraps as needed, like text. To employ the 2x concept, multiply box sizes by two and mix them together.`}</p>
    <p>{`When tiling fixed boxes, the column count is not known in advance, but a grid emerges visually due to the use of a consistent sizing scale. On breakpoint boundaries, fixed sizing scales match column sizes.`}</p>
    <h4 {...{
      "id": "hybrid-grid"
    }}>{`Hybrid grid`}</h4>
    <p><a parentName="p" {...{
        "href": "#hybrid"
      }}>{`Hybrid grids`}</a>{` are also frequent in practice, and hybrid boxes have properties of both.`}</p>
    <h4 {...{
      "id": "decision-tree"
    }}>{`Decision tree`}</h4>
    <p>{`For dense content, use mini units for further alignment and spatial guidance.`}</p>
    <img {...{
      "src": "/5eadef91f662465ebd2f81ff4063997c/Layout_overview_Columns-rows-light-dense.svg",
      "alt": "Columns and rows for dense content"
    }}></img>
    <h3 {...{
      "id": "margins"
    }}>{`Margins`}</h3>
    <p>{`The margins at the outer edge of the grid are always a fixed size within a breakpoint, even when columns are fluid. See `}<a parentName="p" {...{
        "href": "#breakpoints"
      }}>{`breakpoints`}</a>{` for a table of margin sizes.`}</p>
    <img {...{
      "src": "/540521dec8b3851fd1866474884730c6/Layout_overview_Margins.svg",
      "alt": "Margins"
    }}></img>
    <h3 {...{
      "id": "padding"
    }}>{`Padding`}</h3>
    <p>{`Padding is always a fixed multiple of mini units: 16 pixels at all standard breakpoints. Always align type to the edge of box padding. Note, some design tools add padding to text boxes by default; check text box padding and remove it if necessary.`}</p>
    <img {...{
      "src": "/8893a694995a6d89a523161f3983ef37/Layout_overview_Paddings.svg",
      "alt": "Padding"
    }}></img>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample type="do" caption="Always place type against the padding." mdxType="DoDontExample">
          <img {...{
            "src": "/7d8b9a6ee3d649916fea33f50813b3cd/Layout_overview_Paddings-do.svg",
            "alt": "Always place type against the padding."
          }}></img>
  </DoDontExample>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample caption="Never place type on the padding." mdxType="DoDontExample">
          <img {...{
            "src": "/1a0dd4fb8f00b13e376a284400765163/Layout_overview_Paddings-dont.svg",
            "alt": "Never place type on the padding."
          }}></img>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "gutters"
    }}>{`Gutters`}</h3>
    <p>{`Grid gutters can be absent as shown above, or present as shown below. The margin around each grid box matches its padding, for a total gutter of 32 pixels. For closely related content, consider a gutterless grid. Apply gutters when content warrants more separation.`}</p>
    <img {...{
      "src": "/82636d76c3a3a8e785b85febb0596375/Layout_overview_Gutters.svg",
      "alt": "Gutters"
    }}></img>
    <h3 {...{
      "id": "breakpoints"
    }}>{`Breakpoints`}</h3>
    <p>{`Use this set of standard breakpoints to maintain layout integrity across screen sizes. For best results, test designs and code at each of these standard breakpoints.`}</p>
    <p>{`Create custom breakpoints to accommodate special needs, by writing your own media queries and CSS rules. Refer to the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/grid"
      }}>{`grid code package`}</a>{` for more information.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breakpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Columns`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size (%)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Padding`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Margin`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320 / 20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`672 / 42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1056 / 66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X-Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1312 / 82`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Max`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1584 / 99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 px`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For best results, test designs and code at each of these standard breakpoints.`}</p>
    <Video vimeoId="292015984" mdxType="Video" />
    <h2 {...{
      "id": "grid-behaviors"
    }}>{`Grid behaviors`}</h2>
    <p>{`In user interfaces, screen size is dictated by the device and the user’s chosen window size. We may know content in advance, or the content may be dynamic.`}</p>
    <p>{`Carefully consider the user’s goal for their screen space, particularly when displaying dynamic content. If a user’s goal is to see more items, scale column count by tiling fixed boxes. If a user wants to see more content within each item, scale boxes and use fixed column counts.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fluid columns</AnchorLink>
- [Fixed boxes](fixed-boxes)
      <AnchorLink mdxType="AnchorLink">Hybrid boxes</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Key lines</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "fluid-grid-1"
    }}>{`Fluid grid`}</h3>
    <p>{`Fluid column structures are ideal for editorial content, dashboards, images, video, data visualizations, etc. In each case, scaling the size of things is more useful to the user than scaling the number of visible things. In a fluid grid, we divide available width in half to create columns, repeating as necessary.`}</p>
    <p>{`At each breakpoint, column count is fixed and column width is a multiple of mini units. Row height is a multiple of column size, following recommended aspect ratios. Margin and padding are fixed multiples of mini units. In between breakpoints, actual column width is a percentage of the grid area, not a mini unit multiple. Content scales fluidly.`}</p>
    <Video vimeoId="292024522" mdxType="Video" />
    <h3 {...{
      "id": "fixed-boxes"
    }}>{`Fixed boxes`}</h3>
    <p>{`A grid can be formed with fixed boxes by arranging tiles in an inline block, icons in toolbars, etc. Column count grows with browser width. Tiles wrap to the next line, or are sometimes truncated with an overflow scroll.`}</p>
    <Video vimeoId="292024183" mdxType="Video" />
    <p>{`First choose a base size from the `}<a parentName="p" {...{
        "href": "#sizing-scale"
      }}>{`sizing scale`}</a>{`, then build up each box in multiples of the base size, following recommended `}<a parentName="p" {...{
        "href": "#aspect-ratio"
      }}>{`aspect ratios`}</a>{`. A grid emerges when each tile uses a multiple of a consistent base size. Following this method ensures consistency of tile sizes, even across products.`}</p>
    <h3 {...{
      "id": "hybrid-boxes"
    }}>{`Hybrid boxes`}</h3>
    <p>{`Hybrid boxes have different scaling rules in each dimension, so they do not use aspect ratios. When a user would resize the browser expecting content to scale in one dimension but not the other, use hybrid boxes.`}</p>
    <p>{`Use the `}<a parentName="p" {...{
        "href": "#sizing-scale"
      }}>{`sizing scale`}</a>{` to size a fixed dimension. Use column-width multiples to size a grid-fluid dimension. Aspect ratios do not apply.`}</p>
    <Video vimeoId="292025098" mdxType="Video" />
    <p>{`Here are some common UI behaviors:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Width`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Grid)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed (m.u.)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Toolbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Grid)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed (m.u.)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Side Panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Grid)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Menu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Content)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Content)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Data Table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Grid)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fluid (Content)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "key-lines"
    }}>{`Key lines`}</h3>
    <p>{`Regardless of how your grid is built, ensure the overall layout has visible key lines: vertical and horizontal lines on which multiple objects align.`}</p>
    <p>{`Horizontal and vertical alignment are equally critical. When present, the eye can follow content more easily, increasing the perception of visual harmony.`}</p>
    <h2 {...{
      "id": "grid-influencers"
    }}>{`Grid influencers`}</h2>
    <p>{`Within a design system, layouts often follow a common structure, especially with regard to navigation. This section covers which of these UI patterns you’ll need, where users will expect to see them on a page, and how they’ll affect the content that lives within the responsive grid.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Screen regions</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Panel behavior</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "screen-regions"
    }}>{`Screen regions`}</h3>
    <p>{`In a UI layout, users expect to find certain types of content in certain areas. We call these areas `}<em parentName="p">{`screen regions`}</em>{`. It is especially important for these zones to be consistent across devices and adapt across breakpoints. `}<a parentName="p" {...{
        "href": "/experimental/ui-shell/usage"
      }}>{`Learn more about the shell on the experimental UI shell page.`}</a></p>
    <img {...{
      "src": "/ecee1075b9bbac41880288d8e193bbe4/Layout_overview_Screen-regions-1.svg",
      "alt": "Shell Illustation"
    }}></img>
    <Caption mdxType="Caption">1. Header 2. Global sidenav 3. Local sidenav</Caption>
    <img {...{
      "src": "/3c6685912818f0b3b9769acb8219da8b/Layout_overview_Screen-regions-2.svg",
      "alt": "Dropdown Illustation"
    }}></img>
    <Caption mdxType="Caption">4. Dropdown menu 5. Content 6. Footer</Caption>
    <img {...{
      "src": "/1b59fa17b91916311a3f8bddeefcfef7/Layout_overview_Screen-regions-3.svg",
      "alt": "Dialog Illustation"
    }}></img>
    <Caption mdxType="Caption">7. Dialog</Caption>
    <h3 {...{
      "id": "panel-behavior"
    }}>{`Panel behavior`}</h3>
    <p>{`All vertical panels expand to fill the full height of the browser window.`}</p>
    <h4 {...{
      "id": "flexible-panels"
    }}>{`Flexible panels`}</h4>
    <p>{`The flexible panels allow for both collapsed and expanded states. The expanded state of a flexible Panel is a fixed width that cannot be adjusted by the user. The collapsed Flexible Panel expands when the user hovers over any portion of it. When flexible panels expand, they either condense both the content and the grid or they push content beyond the edge of the browser.`}</p>
    <Video vimeoId="292975726" mdxType="Video" />
    <br />
    <br />
    <h4 {...{
      "id": "fixed-panels"
    }}>{`Fixed panels`}</h4>
    <p>{`Fixed panels maintain a static width, cannot be collapsed, and also exist outside of the responsive grid.`}</p>
    <Video vimeoId="292988292" mdxType="Video" />
    <br />
    <br />
    <h4 {...{
      "id": "floating-panels"
    }}>{`Floating panels`}</h4>
    <p>{`This panel style floats above the primary content area and does not affect the responsive grid. Floating panels conceal any UI elements below them and must be dismissable by the user. Inline menus, dropdowns and tooltips also float.`}</p>
    <Video vimeoId="292977039" mdxType="Video" />
    <h2 {...{
      "id": "sizing-scale"
    }}>{`Sizing scale`}</h2>
    <p>{`We provide a sizing scale for both fixed and fluid sizes. Use it to size content as well as negative space. Like a type scale, the sizing scale constrains the range of sizes to improve consistency and rhythm, while still allowing a wide range of sizes. Spacing tokens are used to apply these relationships consistently across UIs.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fixed base unit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Fluid base unit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Scaling multiple</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Aspect ratio</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Vertical rhythm</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Components</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "fixed-base-unit"
    }}>{`Fixed base unit`}</h3>
    <p>{`Use this fixed sizing scale, comprised of select mini unit multiples, when sizing icons, tiling boxes, and applying vertical margin space.`}</p>
    <img {...{
      "src": "/9b93a7180ed8f492aca4a79c510f5ef8/Layout_overview_Fixed-base-unit.svg",
      "alt": "Fixed base unit"
    }}></img>
    <p>{`Choose a size to use as your fixed base unit. Also see the `}<a parentName="p" {...{
        "href": "#breakpoints"
      }}>{`breakpoints table`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sizing scale (px)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Mini units`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10x`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "fluid-base-unit"
    }}>{`Fluid base unit`}</h3>
    <p>{`For fluid grids, the column width is the base unit. Just as a box can span multiple columns, its height is a multiple of column width. Use cases are leading section in a webpage, divded section for editorial content, modals, etc. Check out the `}<a parentName="p" {...{
        "href": "#breakpoints"
      }}>{`breakpoints table`}</a>{` for pixel sizings.`}</p>
    <img {...{
      "src": "/bc32290b3061521b11cf6296a028a9d9/Layout_overview_Fluid-base-unit.svg",
      "alt": "Fluid base unit"
    }}></img>
    <h3 {...{
      "id": "scaling-multiple"
    }}>{`Scaling multiple`}</h3>
    <p>{`After determining your base size, multiply it as needed for each use. For box sizing, apply an `}<a parentName="p" {...{
        "href": "#aspect-ratio"
      }}>{`aspect ratio`}</a>{` too.`}</p>
    <Video vimeoId="292026497" mdxType="Video" />
    <h3 {...{
      "id": "aspect-ratio"
    }}>{`Aspect ratio`}</h3>
    <p>{`When sizing boxes, constrain dimensions to one of the aspect ratios shown below whenever possible. Doing so will heighten the perception of unity between products.`}</p>
    <p>{`Choose an aspect ratio from the table, then multiply the base unit in each dimension as needed to set width and height, maintaining the ratio in either portrait or landscape.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Aspect ratios`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1:1, 2:1, 2:3, 3:2, 4:3, 16:9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`For example, to size a fluid box with a 2:1 landscape aspect ratio, you could span 4 columns in width and 2 column-widths of height. To size a fixed box with the same aspect ratio, you could start with an 80 pixel base unit, then multiply it by 4 in width and 2 in height, for a fixed box size of 320 by 160.`}</p>
    <img {...{
      "src": "/8bc822065abf171bc399ad25870d147e/Layout_overview_Aspect-ratio.svg",
      "alt": "aspect ratio"
    }}></img>
    <h3 {...{
      "id": "vertical-rhythm"
    }}>{`Vertical rhythm`}</h3>
    <p>{`Use the fixed sizing scale to set vertical spacing between sections, by adding it to the top or bottom margin of a box:`}</p>
    <Video vimeoId="292028388" mdxType="Video" />
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample type="do" caption="Mini unit is the guide when needed. Spacer snaps to the text box and does not necessarily need to snap to the mini unit." mdxType="DoDontExample">
          <img {...{
            "src": "/ac39caec3b35832a83c12e5679444025/Layout_overview_Visual-rhythm-do.svg",
            "alt": "Mini unit is the guide when needed. Spacer snaps to the text box and does not necessarily need to snap to the mini unit."
          }}></img>
  </DoDontExample>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample caption="In Sketch, always use “Auto” in alignment to ensure the text box is fitting with the content. Spacer snaps to the text box." mdxType="DoDontExample">
          <img {...{
            "src": "/de96bb05e5a37a5f8fb6453a50e34f22/Layout_overview_Visual-rhythm-dont.svg",
            "alt": "Always use “Auto” in alignment to ensure the text box is fitting with the content. Spacer snaps to the text box."
          }}></img>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "components"
    }}>{`Components`}</h3>
    <p>{`Components can be sized like other boxes: either fixed or fluid, and often hybrid, with fixed height and fluid width. When interior space within a component is cramped, use the minor sizing scale:`}</p>
    <p>{`When multiple components adhere to the same scale, grid relationships naturally emerge. For example, vertically aligning form fields such as checkboxes and radio buttons produces a grid effect when interior spacing is consistent.`}</p>
    <Video vimeoId="292028752" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      